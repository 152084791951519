#loading {
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
}
#loading .dot {
  width: 24px;
  height: 24px;
  background: #3ac;
  border-radius: 100%;
  display: inline-block;
  -webkit-animation: slide 1s infinite;
          animation: slide 1s infinite;
}
#loading .dot:nth-child(1) {
  -webkit-animation-delay: 0.1s;
          animation-delay: 0.1s;
  background: #32aacc;
}
#loading .dot:nth-child(2) {
  -webkit-animation-delay: 0.2s;
          animation-delay: 0.2s;
  background: #64aacc;
}
#loading .dot:nth-child(3) {
  -webkit-animation-delay: 0.3s;
          animation-delay: 0.3s;
  background: #96aacc;
}
#loading .dot:nth-child(4) {
  -webkit-animation-delay: 0.4s;
          animation-delay: 0.4s;
  background: #c8aacc;
}
#loading .dot:nth-child(5) {
  -webkit-animation-delay: 0.5s;
          animation-delay: 0.5s;
  background: #faaacc;
}
@-webkit-keyframes slide {
  0% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
  50% {
    opacity: 0.3;
    -webkit-transform: scale(2);
            transform: scale(2);
  }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
}
@keyframes slide {
  0% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
  50% {
    opacity: 0.3;
    -webkit-transform: scale(2);
            transform: scale(2);
  }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
}

